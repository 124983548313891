import React from 'react';
import { UtilityText } from '@policygenius/mortar';
import {
  BadgesWrapper,
  ClientName,
  CoverageAmountCell,
  Empty,
  HiddenMobileCell,
  LastUpdatedCell,
  ListNumberCell,
  NameAndDate,
  ReferredBy,
  ReferringAgentName,
  RightChevronCell,
  StatusBadge,
  TableData,
  TableRow,
  Spacer,
  CreatedDate,
} from './styles';
import { Props } from './types';
import { getDate, getYear, format, parse } from 'date-fns';
import { STATUS_BADGE } from '../constants';
import Tracker from 'components/Tracker';
import { dateDifference, nameCase, usdFormat } from 'utils/helpers';
import { STAGE_MAPPING } from 'components/Tracker/types';

const StatusTrackerItem: React.FC<Props> = ({
  report,
  listNumber,
  selected,
  anySelected,
  handleDetail,
  isMultiTierAdmin,
  showProductTypeColumn,
}) => {
  const statusBadge = STATUS_BADGE[report.applicationStatus];
  const stageNumber = STAGE_MAPPING[report.applicationStage];
  const createdDate = parse(report.createdDate.slice(0, -4), 'yyyy-MM-dd H:mm:ss xx', new Date());

  const referredDate = `${format(createdDate, 'MMM')} ${getDate(createdDate)}, ${getYear(
    createdDate
  )}`;

  const referringAgentName = report.referringAgent
    ? `${report.referringAgent?.lastName}, ${report.referringAgent?.firstName[0]}.`
    : '';

  const formattedCreatedBy = nameCase(report.creationMethod);

  return (
    <TableRow
      selected={selected}
      key={report.lifeAppliedPolicy?.carrier}
      onClick={() => {
        handleDetail(listNumber);
      }}
    >
      <ListNumberCell>
        <UtilityText size="medium" color="black">
          {listNumber}
        </UtilityText>
      </ListNumberCell>
      <TableData>
        <NameAndDate>
          <ClientName
            size="small"
            weight="bold"
          >{`${report.client.lastName}, ${report.client.firstName[0]}.`}</ClientName>
          <CreatedDate size="small">{referredDate}</CreatedDate>
        </NameAndDate>
        <BadgesWrapper>
          <Tracker
            title={report.applicationStage}
            number={stageNumber}
            full={report.applicationStage === 'PLACED'}
          />
          {statusBadge ? (
            <StatusBadge label={statusBadge.statusName} variant={statusBadge.variant} />
          ) : (
            <Empty />
          )}
        </BadgesWrapper>
        <ReferredBy>
          {isMultiTierAdmin && (
            <>
              <UtilityText size="xsmall">{report.referringAgent.agencyName}</UtilityText>
              <Spacer size="xsmall">|</Spacer>
            </>
          )}
          <UtilityText size="xsmall">Referred by&nbsp;</UtilityText>
          <ReferringAgentName size="xsmall">{referringAgentName}</ReferringAgentName>
        </ReferredBy>
      </TableData>
      {isMultiTierAdmin && (
        <HiddenMobileCell>
          <UtilityText size="small">{report.referringAgent.agencyName}</UtilityText>
        </HiddenMobileCell>
      )}
      <HiddenMobileCell>
        <ReferringAgentName size="small">{referringAgentName}</ReferringAgentName>
      </HiddenMobileCell>
      {showProductTypeColumn && (
        <HiddenMobileCell>
          <UtilityText size="small">{report.lifeAppliedPolicy?.product}</UtilityText>
        </HiddenMobileCell>
      )}
      <HiddenMobileCell>
        <Tracker
          title={report.applicationStage}
          number={stageNumber}
          full={report.applicationStage === 'PLACED'}
        />
      </HiddenMobileCell>
      <HiddenMobileCell>
        {statusBadge ? (
          <StatusBadge label={statusBadge.statusName} variant={statusBadge.variant} />
        ) : (
          <Empty />
        )}
      </HiddenMobileCell>
      <CoverageAmountCell selectedReport={anySelected}>
        {report.lifeAppliedPolicy?.premium ? (
          <UtilityText size="small">{usdFormat(report.lifeAppliedPolicy.premium)}</UtilityText>
        ) : (
          <Empty rightAlign />
        )}
      </CoverageAmountCell>
      {!isMultiTierAdmin && (
        <CoverageAmountCell selectedReport={anySelected}>
          <UtilityText size="small">{formattedCreatedBy}</UtilityText>
        </CoverageAmountCell>
      )}
      <CoverageAmountCell selectedReport={anySelected}>
        <UtilityText size="small">{referredDate}</UtilityText>
      </CoverageAmountCell>
      <LastUpdatedCell isMultiTierAdmin={isMultiTierAdmin}>
        <UtilityText size="small">{dateDifference(report.updatedDate)}</UtilityText>
      </LastUpdatedCell>
      <RightChevronCell>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.30672 6.70887C7.89776 6.29991 7.89776 5.65726 8.30672 5.30672C8.71568 4.89776 9.35833 4.89776 9.70887 5.30672L15.7264 11.3243C16.077 11.6748 16.1354 12.3175 15.7848 12.7264L10.2931 18.6856C9.94256 19.0945 9.29991 19.0945 8.89095 18.744C8.48199 18.3934 8.48199 17.7508 8.83253 17.3418L13.6816 12.0253L8.30672 6.70887Z"
            fill="#C0C0C0"
          />
        </svg>
      </RightChevronCell>
    </TableRow>
  );
};

export default StatusTrackerItem;
