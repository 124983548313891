import React from 'react';
import {
  Col,
  Row,
  Selectbox,
  Textbox,
  ToggleGroup,
  Textarea,
  UtilityText,
} from '@policygenius/mortar';
import {
  SubmitOrContinueButton,
  Disclaimer,
  Email,
  Recommendation,
  RecSubtext,
  SharedBottomMargin,
  Title,
  RecommendationCol,
  CardOrder,
} from './styles';
import { useField } from 'react-final-form';
import DateField from 'components/DateField';
import { STATES, TERM_LENGTHS } from 'pages/ClientInfo/form/constants';
import { useFormContext } from 'context/FormContext';
import PolicyPlayback from 'components/PolicyPlayback';
import { Gender, AgencyType, Role } from 'graph/types';
import { useAuth0 } from '@auth0/auth0-react';
import {
  DEFAULT_OFFERING,
  MOTIVATIONS,
  PRODUCT_OFFERINGS_TYPES,
  PAYMENT_MODES,
  Props,
  GUARANTEED_AGE,
  PAY_AGE,
  SPWL_PAYMENT_MODE,
  PRODUCT_DESCRIPTION,
  PERM_PRODUCT_OFFERINGS,
  DEFAULT_PERM_OFFERING,
} from './types';
import { toString, userHasSelectedPolicy } from 'utils/helpers';
import MaskedTextField from 'components/MaskedTextField';
import { numberMask, phoneMask } from 'components/MaskedTextField/constants';
import { useCurrentUserQuery } from 'query/currentUser/query';
import AdvisorSelect from 'components/AdvisorSelect';
import { useAvailableProducersQuery } from 'query/producers/query';
import { ProductDecisionCard } from 'components/ProductDecisionCard';

/* eslint-disable complexity */
const DropTicketForm: React.FC<Props> = ({ handleSubmit, invalid, submitting, moreProducts }) => {
  const firstName = useField('firstName');
  const lastName = useField('lastName');
  const gender = useField('gender');
  const dateOfBirth = useField('dateOfBirth');
  const stateUS = useField('state');
  const phone = useField('phone');
  const email = useField('email');
  const coverageAmount = useField('coverageAmount');
  const term = useField('term');
  const notes = useField('notes');
  const referringAgent = useField('referringAgent');
  const { isAuthenticated } = useAuth0();

  // new fields
  const motivation = useField('motivation');
  const strategy = useField('strategy');
  const productType = useField('productType', {
    initialValue: DEFAULT_OFFERING.value,
  });
  const permProductType = useField('permProductType', {
    initialValue: DEFAULT_PERM_OFFERING.value,
  });
  const income = useField('guaranteedAge', {
    parse: (value) => (value === '' ? undefined : Number(value)),
  });

  // / SPWL fields
  const targetPremiumSPWL = useField('targetPremiumSPWL', {
    parse: (value) => (value === '' ? undefined : Number(value)),
  });
  const paymentMode = useField('paymentMode');
  //
  // TERM fields
  const paymentModeTerm = useField('paymentModeTerm');
  // end

  // GUL fields
  // const guaranteedAge = useField('guaranteedAge');
  const guaranteedAge = useField('guaranteedAge', {
    parse: (value) => (value === '' ? undefined : Number(value)),
  });
  const paymentModeGUL = useField('paymentModeGUL');
  // end
  //
  // whole life fields
  const payAgeWhole = useField('pageAgeWhole');
  const paymentModelWhole = useField('paymentModelWhole');
  // end
  //
  // ILU life fields
  const paymentModeIUL = useField('paymentModeIUL');
  // end
  const { state } = useFormContext();

  const submitOrContinue = isAuthenticated ? 'Submit' : 'Continue';

  const legalCopy = `By clicking ${submitOrContinue}, you confirm that you've received written consent from the above-referenced client 1) to share such client's contact information (email and phone number, as entered above) with Policygenius, and 2) for Policygenius to contact such client directly via text message and live, automated and pre-recorded telephone calls using the information provided.`;

  const { data: userData, loading, error } = useCurrentUserQuery();

  if (error) {
    console.error(error);
  }

  const {
    data: advisorData,
    loading: loadingAdvisor,
    error: errorAdvisor,
  } = useAvailableProducersQuery();

  if (errorAdvisor) {
    console.error(errorAdvisor);
  }

  const assistant = userData?.currentUser?.roles.includes(Role.Assistant);

  const notesHelperText =
    userData?.currentUser.agencyType === AgencyType.FeeOnly
      ? 'Please do not include client health details'
      : undefined;

  return (
    <form onSubmit={handleSubmit} noValidate>
      {!loading && assistant && !loadingAdvisor && (
        <AdvisorSelect
          referringAgent={referringAgent}
          listOfAgents={advisorData?.availableProducers || []}
        />
      )}
      <Title
        size={{
          small: 'medium',
        }}
      >
        Client information
      </Title>
      <Row nested>
        <Col
          span={{
            medium: 6,
            small: 4,
          }}
        >
          <SharedBottomMargin>
            <Textbox
              {...firstName.input}
              id="first-name"
              name="first-name"
              label="First name"
              placeholder="Enter"
              value={firstName.input.value}
              defaultValue=""
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                firstName.input.onChange(e.target.value);
              }}
              type="text"
              errorMessage={
                firstName.meta.touched && !firstName.meta.active ? firstName.meta.error : undefined
              }
            />
          </SharedBottomMargin>
        </Col>
        <Col
          span={{
            medium: 6,
            small: 4,
          }}
        >
          <SharedBottomMargin>
            <Textbox
              {...lastName.input}
              id="last-name"
              name="last-name"
              label="Last name"
              placeholder="Enter"
              value={lastName.input.value}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                lastName.input.onChange(e.target.value)
              }
              type="text"
              errorMessage={
                lastName.meta.touched && !lastName.meta.active ? lastName.meta.error : undefined
              }
            />
          </SharedBottomMargin>
        </Col>
      </Row>
      <SharedBottomMargin>
        <ToggleGroup
          {...gender.input}
          groupLabel="Gender"
          defaultValue={toString(gender.meta.initial)}
          errorMessage={gender.meta.touched && !gender.meta.active ? gender.meta.error : undefined}
          name="gender_selection"
          options={[
            { label: 'Male', value: Gender.Male },
            { label: 'Female', value: Gender.Female },
          ]}
          showGroupLabel
        />
      </SharedBottomMargin>
      <Row nested>
        <Col
          span={{
            medium: 8,
            small: 4,
          }}
        >
          <SharedBottomMargin>
            <DateField label="Date of birth" {...dateOfBirth} initialDate={state.dateOfBirth} />
          </SharedBottomMargin>
        </Col>
        <Col
          span={{
            medium: 4,
            small: 4,
          }}
        >
          <SharedBottomMargin>
            <Selectbox
              id="state"
              name="select-box"
              label="State"
              options={STATES}
              defaultValue={stateUS.input.value}
              errorMessage={
                stateUS.meta.touched && !stateUS.meta.active ? stateUS.meta.error : undefined
              }
              onChange={stateUS.input.onChange} // eslint-disable-line react/jsx-handler-names
              onBlur={stateUS.input.onBlur} // eslint-disable-line react/jsx-handler-names
            />
          </SharedBottomMargin>
        </Col>
      </Row>

      <SharedBottomMargin>
        <MaskedTextField
          input={phone.input}
          meta={phone.meta}
          id="phone"
          label="Phone number"
          showLabel
          placeholder="Enter"
          type="tel"
          defaultValue={phone.input.value}
          maskOptions={phoneMask}
        />
      </SharedBottomMargin>
      <Email>
        <UtilityText size="medium" weight="bold">
          Email address
        </UtilityText>
        <UtilityText size="medium">
          Each referral must have a unique email address, including spousals.
        </UtilityText>
        <Textbox
          {...email.input}
          id="email"
          name="email"
          label=""
          placeholder="Enter"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            email.input.onChange(e.target.value)
          }
          type="text"
          errorMessage={email.meta.touched && !email.meta.active ? email.meta.error : undefined}
        />
      </Email>
      <Recommendation size="medium">Recommendation (optional)</Recommendation>
      <RecSubtext
        size={{
          medium: 'large',
          small: 'medium',
        }}
      >
        Please note that as our agents conduct a detailed health history review,{' '}
        <strong>carrier, coverage and term are subject to change.</strong>
      </RecSubtext>
      {userHasSelectedPolicy(state) && <PolicyPlayback />}
      {!userHasSelectedPolicy(state) && !moreProducts && (
        <Row nested>
          <Col
            span={{
              medium: 6,
              small: 4,
            }}
          >
            <SharedBottomMargin data-testid="coverageAmount">
              <MaskedTextField
                input={coverageAmount.input}
                meta={coverageAmount.meta}
                id="coverage-amount"
                label="Coverage amount"
                showLabel
                placeholder="0"
                type="text"
                defaultValue={coverageAmount.input.value}
                maskOptions={numberMask}
                prefix="$"
              />
            </SharedBottomMargin>
          </Col>
          <Col
            span={{
              medium: 6,
              small: 4,
            }}
          >
            <SharedBottomMargin data-testid="termLength">
              <Selectbox
                id="term"
                name="select-box"
                label="Term length"
                options={TERM_LENGTHS}
                defaultValue={term.input.value}
                errorMessage={term.meta.touched && !term.meta.active ? term.meta.error : undefined}
                onChange={term.input.onChange} // eslint-disable-line react/jsx-handler-names
                onBlur={term.input.onBlur} // eslint-disable-line react/jsx-handler-names
              />
            </SharedBottomMargin>
          </Col>
        </Row>
      )}
      {moreProducts && !userHasSelectedPolicy(state) && (
        <>
          <Row nested>
            <RecommendationCol span={{ small: 4, medium: 6 }}>
              <ToggleGroup
                {...strategy.input}
                errorMessage=""
                groupLabel="Strategy"
                name="strategy"
                options={[
                  {
                    label: 'Protection',
                    value: 'protection',
                  },
                  {
                    label: 'Accumulation',
                    value: 'accumulation',
                  },
                ]}
                showGroupLabel
              />
            </RecommendationCol>
            <RecommendationCol span={{ small: 4, medium: 6 }}>
              <Selectbox
                id="motivation"
                name="select-box"
                label="Customer motivations"
                options={MOTIVATIONS}
                errorMessage={
                  motivation.meta.touched && !motivation.meta.active
                    ? motivation.meta.error
                    : undefined
                }
                onChange={motivation.input.onChange} // eslint-disable-line react/jsx-handler-names
                onBlur={motivation.input.onBlur} // eslint-disable-line react/jsx-handler-names
              />
            </RecommendationCol>
            <RecommendationCol span={{ small: 4, medium: 6 }}>
              <Selectbox
                id="productType"
                name="select-box"
                label="Product type"
                options={PRODUCT_OFFERINGS_TYPES}
                defaultValue={productType}
                errorMessage={
                  productType.meta.touched && !productType.meta.active
                    ? productType.meta.error
                    : undefined
                }
                onChange={productType.input.onChange} // eslint-disable-line react/jsx-handler-names
                onBlur={productType.input.onBlur} // eslint-disable-line react/jsx-handler-names
              />
            </RecommendationCol>
            <RecommendationCol span={{ small: 4, medium: 6 }}>
              <MaskedTextField
                input={income.input}
                meta={income.meta}
                id="insured-income"
                label="Insured income"
                showLabel
                placeholder="0"
                type="text"
                defaultValue={income.input.value}
                maskOptions={numberMask}
                prefix="$"
              />
            </RecommendationCol>
            {productType.input.value === 'permanent' && (
              <RecommendationCol span={{ small: 4, medium: 6 }}>
                <Selectbox
                  id="permPoductType"
                  name="select-box"
                  label="Perm product type"
                  options={PERM_PRODUCT_OFFERINGS}
                  defaultValue={permProductType}
                  errorMessage={
                    productType.meta.touched && !productType.meta.active
                      ? productType.meta.error
                      : undefined
                  }
                  onChange={permProductType.input.onChange} // eslint-disable-line react/jsx-handler-names
                  onBlur={permProductType.input.onBlur} // eslint-disable-line react/jsx-handler-names
                />
              </RecommendationCol>
            )}
            {productType.input.value === 'term' && (
              <RecommendationCol span={{ small: 4, medium: 12 }}>
                <ProductDecisionCard title="Term Life" description={PRODUCT_DESCRIPTION.term}>
                  <CardOrder>
                    <MaskedTextField
                      input={coverageAmount.input}
                      meta={coverageAmount.meta}
                      id="coverage-amount"
                      label="Coverage amount"
                      showLabel
                      placeholder="0"
                      type="text"
                      defaultValue={coverageAmount.input.value}
                      maskOptions={numberMask}
                      prefix="$"
                    />
                    <Selectbox
                      id="term"
                      name="select-box"
                      label="Term length"
                      options={TERM_LENGTHS}
                      defaultValue={term.input.value}
                      errorMessage={
                        term.meta.touched && !term.meta.active ? term.meta.error : undefined
                      }
                      onChange={term.input.onChange} // eslint-disable-line react/jsx-handler-names
                      onBlur={term.input.onBlur} // eslint-disable-line react/jsx-handler-names
                    />
                    <Selectbox
                      id="paymentMode"
                      name="select-box"
                      label="Payment mode"
                      options={PAYMENT_MODES}
                      defaultValue={PAYMENT_MODES[0]}
                      errorMessage={
                        paymentModeTerm.meta.touched && !paymentModeTerm.meta.active
                          ? paymentModeTerm.meta.error
                          : undefined
                      }
                      onChange={paymentModeTerm.input.onChange} // eslint-disable-line react/jsx-handler-names
                      onBlur={paymentModeTerm.input.onBlur} // eslint-disable-line react/jsx-handler-names
                    />
                  </CardOrder>
                </ProductDecisionCard>
              </RecommendationCol>
            )}
            {productType.input.value === 'permanent' &&
              permProductType.input.value === 'select' && (
                <RecommendationCol span={{ small: 4, medium: 12 }}>
                  <ProductDecisionCard title="Perm Life" description={PRODUCT_DESCRIPTION.perm}>
                    <CardOrder>
                      <MaskedTextField
                        input={coverageAmount.input}
                        meta={coverageAmount.meta}
                        id="coverage-amount"
                        label="Coverage amount"
                        showLabel
                        placeholder="0"
                        type="text"
                        defaultValue={coverageAmount.input.value}
                        maskOptions={numberMask}
                        prefix="$"
                      />
                      <Selectbox
                        id="paymentMode"
                        name="select-box"
                        label="Payment mode"
                        options={PAYMENT_MODES}
                        defaultValue={PAYMENT_MODES[0]}
                        errorMessage={
                          paymentMode.meta.touched && !paymentMode.meta.active
                            ? paymentMode.meta.error
                            : undefined
                        }
                        onChange={paymentMode.input.onChange} // eslint-disable-line react/jsx-handler-names
                        onBlur={paymentMode.input.onBlur} // eslint-disable-line react/jsx-handler-names
                      />
                    </CardOrder>
                  </ProductDecisionCard>
                </RecommendationCol>
              )}
            {productType.input.value === 'permanent' && permProductType.input.value === 'spwl' && (
              <RecommendationCol span={{ small: 4, medium: 12 }}>
                <ProductDecisionCard
                  title="Single Premium whole life (SPWL)"
                  description={PRODUCT_DESCRIPTION.spwl}
                >
                  <CardOrder>
                    <MaskedTextField
                      input={coverageAmount.input}
                      meta={coverageAmount.meta}
                      id="coverage-amount"
                      label="Coverage amount"
                      showLabel
                      placeholder="0"
                      type="text"
                      defaultValue={coverageAmount.input.value}
                      maskOptions={numberMask}
                      prefix="$"
                    />
                    <MaskedTextField
                      input={targetPremiumSPWL.input}
                      meta={targetPremiumSPWL.meta}
                      id="insured-targetPremiumSPWL"
                      label="Target Premium"
                      showLabel
                      placeholder="0"
                      type="text"
                      defaultValue={targetPremiumSPWL.input.value}
                      maskOptions={numberMask}
                      prefix="$"
                    />
                    <Selectbox
                      id="paymentMode"
                      name="select-box"
                      label="Payment mode"
                      options={SPWL_PAYMENT_MODE}
                      defaultValue={SPWL_PAYMENT_MODE[0]}
                      errorMessage={
                        paymentMode.meta.touched && !paymentMode.meta.active
                          ? paymentMode.meta.error
                          : undefined
                      }
                      onChange={paymentMode.input.onChange} // eslint-disable-line react/jsx-handler-names
                      onBlur={paymentMode.input.onBlur} // eslint-disable-line react/jsx-handler-names
                    />
                  </CardOrder>
                </ProductDecisionCard>
              </RecommendationCol>
            )}
            {productType.input.value === 'permanent' && permProductType.input.value === 'gul' && (
              <RecommendationCol span={{ small: 4, medium: 12 }}>
                <ProductDecisionCard
                  title="Guaranteed Universal Life (GUL)"
                  description={PRODUCT_DESCRIPTION.gul}
                >
                  <CardOrder>
                    <MaskedTextField
                      input={coverageAmount.input}
                      meta={coverageAmount.meta}
                      id="coverage-amount"
                      label="Coverage amount"
                      showLabel
                      placeholder="0"
                      type="text"
                      defaultValue={coverageAmount.input.value}
                      maskOptions={numberMask}
                      prefix="$"
                    />
                    <Selectbox
                      id="guaranteed-age"
                      name="select-box"
                      label="Guaranteed Age"
                      options={GUARANTEED_AGE}
                      errorMessage={
                        guaranteedAge.meta.touched && !guaranteedAge.meta.active
                          ? guaranteedAge.meta.error
                          : undefined
                      }
                      onChange={guaranteedAge.input.onChange} // eslint-disable-line react/jsx-handler-names
                      onBlur={guaranteedAge.input.onBlur} // eslint-disable-line react/jsx-handler-names
                    />
                    <Selectbox
                      id="paymentModeGUL"
                      name="select-box"
                      label="Payment mode"
                      options={PAYMENT_MODES}
                      defaultValue={PAYMENT_MODES[0]}
                      errorMessage={
                        paymentModeGUL.meta.touched && !paymentModeGUL.meta.active
                          ? paymentModeGUL.meta.error
                          : undefined
                      }
                      onChange={paymentModeGUL.input.onChange} // eslint-disable-line react/jsx-handler-names
                      onBlur={paymentModeGUL.input.onBlur} // eslint-disable-line react/jsx-handler-names
                    />
                  </CardOrder>
                </ProductDecisionCard>
              </RecommendationCol>
            )}
            {productType.input.value === 'permanent' &&
              permProductType.input.value === 'wholeLife' && (
                <RecommendationCol span={{ small: 4, medium: 12 }}>
                  <ProductDecisionCard
                    title="Whole Life"
                    description={PRODUCT_DESCRIPTION.wholeLife}
                  >
                    <CardOrder>
                      <MaskedTextField
                        input={coverageAmount.input}
                        meta={coverageAmount.meta}
                        id="coverage-amount"
                        label="Coverage amount"
                        showLabel
                        placeholder="0"
                        type="text"
                        defaultValue={coverageAmount.input.value}
                        maskOptions={numberMask}
                        prefix="$"
                      />
                      <Selectbox
                        id="pay-age"
                        name="select-box"
                        label="Pay Age"
                        options={PAY_AGE}
                        errorMessage={
                          payAgeWhole.meta.touched && !payAgeWhole.meta.active
                            ? payAgeWhole.meta.error
                            : undefined
                        }
                        onChange={payAgeWhole.input.onChange} // eslint-disable-line react/jsx-handler-names
                        onBlur={payAgeWhole.input.onBlur} // eslint-disable-line react/jsx-handler-names
                      />
                      <Selectbox
                        id="paymentModeWhole"
                        name="select-box"
                        label="Payment mode"
                        options={PAYMENT_MODES}
                        defaultValue={PAYMENT_MODES[0]}
                        errorMessage={
                          paymentModelWhole.meta.touched && !paymentModelWhole.meta.active
                            ? paymentModelWhole.meta.error
                            : undefined
                        }
                        onChange={paymentModelWhole.input.onChange} // eslint-disable-line react/jsx-handler-names
                        onBlur={paymentModelWhole.input.onBlur} // eslint-disable-line react/jsx-handler-names
                      />
                    </CardOrder>
                  </ProductDecisionCard>
                </RecommendationCol>
              )}
            {productType.input.value === 'permanent' && permProductType.input.value === 'iul' && (
              <RecommendationCol span={{ small: 4, medium: 12 }}>
                <ProductDecisionCard
                  title="Indexed Universal Life (IUL) "
                  description={PRODUCT_DESCRIPTION.iul}
                >
                  <CardOrder>
                    <MaskedTextField
                      input={coverageAmount.input}
                      meta={coverageAmount.meta}
                      id="coverage-amount"
                      label="Coverage amount"
                      showLabel
                      placeholder="0"
                      type="text"
                      defaultValue={coverageAmount.input.value}
                      maskOptions={numberMask}
                      prefix="$"
                    />
                    <Selectbox
                      id="paymentModeIUL"
                      name="select-box"
                      label="Payment mode"
                      options={PAYMENT_MODES}
                      defaultValue={PAYMENT_MODES[0]}
                      errorMessage={
                        paymentModeIUL.meta.touched && !paymentModeIUL.meta.active
                          ? paymentModeIUL.meta.error
                          : undefined
                      }
                      onChange={paymentModeIUL.input.onChange} // eslint-disable-line react/jsx-handler-names
                      onBlur={paymentModeIUL.input.onBlur} // eslint-disable-line react/jsx-handler-names
                    />
                  </CardOrder>
                </ProductDecisionCard>
              </RecommendationCol>
            )}
          </Row>
        </>
      )}
      <SharedBottomMargin>
        <Textarea
          id="notes"
          label="Notes for Policygenius"
          rows={6}
          {...notes.input}
          errorMessage={notes.meta.touched && !notes.meta.active ? notes.meta.error : undefined}
          helperText={notesHelperText}
        />
      </SharedBottomMargin>
      {!userData?.currentUser.tcpaRequired && <Disclaimer size="small">{legalCopy}</Disclaimer>}
      <SubmitOrContinueButton
        analyticsText="Continue"
        size="large"
        onClick={() => null}
        disabled={invalid || submitting}
        variant="black"
      >
        {submitOrContinue}
      </SubmitOrContinueButton>
    </form>
  );
};

export default DropTicketForm;
