import styled from 'styled-components';
import { Checkbox, Button, theme } from '@policygenius/mortar';

export const ResetButton = styled(Button)`
  margin-left: 16px;
`;

export const Status = styled.div`
  display: none;
  margin-top: 24px;

  span {
    pointer-events: none;
  }

  @media (min-width: ${theme.breakpoints.large}px) {
    display: block;
  }
`;

export const FilterCheckBox = styled(Checkbox)`
  padding: 8px 0px;
  width: 100%;
`;

export const FilterContainer = styled.div`
  height: 416px;
  position: absolute;
  padding-bottom: 4px;
  margin-top: 8px;
  z-index: 2;

  label:last-of-type {
    margin-bottom: 38px;
  }
`;
export const DesktopFilterDropDown = styled.div`
  width: 272px;
  height: 416px;
  position: absolute;
  padding: 4px 16px;
  background: ${theme.colors.white};
  overflow-y: auto;
  box-shadow: 0px 5px 10px 0px #00000040;
  border-radius: 4px;
}
`;

export const Reset = styled.div`
  display: flex;
  align-items: center;
  width: 272px;
  height: 48px;
  background: ${theme.colors.white};
  position: absolute;
  bottom: 0;
  padding: 12px 16px 12px 16px;
  box-shadow: 0px -1px 8px 0px #0000000d;
`;
