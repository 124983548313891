import { Col, theme, TitleText, UtilityText } from '@policygenius/mortar';
import styled from 'styled-components';
import Button from 'components/Button';

export const Title = styled(TitleText)`
  margin-bottom: 24px;
`;

export const Disclaimer = styled(UtilityText)`
  margin-bottom: 32px;
  @media (min-width: ${theme.breakpoints.medium}px) {
    margin-bottom: 40px;
  }
`;

export const Email = styled.div`
  margin-bottom: 48px;
  @media (min-width: ${theme.breakpoints.medium}px) {
    margin-bottom: 64px;
  }
`;

export const Recommendation = styled(TitleText)`
  margin-bottom: 8px;
`;

export const RecSubtext = styled(UtilityText)`
  margin-bottom: 24px;
`;

export const SharedBottomMargin = styled.div`
  margin-bottom: 32px;
`;

export const SubmitOrContinueButton = styled(Button)`
  display: block;
  margin: 8px auto;
  width: 100%;
  @media (min-width: ${theme.breakpoints.medium}px) {
    margin: 0px 0px 0px auto;
    width: initial;
  }
`;

export const RecommendationCol = styled(Col)`
  margin-bottom: 32px;
`;
export const CardOrder = styled.div`
  display: flex;
  gap: 24px;

  div {
    flex: 1;
  }
`;
