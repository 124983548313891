import { InitialValues } from 'forms/types';
import { FormRenderProps } from 'react-final-form';
import { LifeApplicationInfo } from 'context/FormContext/types';

export type Props = FormRenderProps<LifeApplicationInfo, InitialValues<LifeApplicationInfo>> & {
  moreProducts: boolean;
};

export const PRODUCT_OFFERINGS_TYPES = [
  { label: 'Term Life', value: 'term' },
  { label: 'Perm Life', value: 'permanent' },
];

export const PERM_PRODUCT_OFFERINGS = [
  { label: 'Select', value: 'select' },
  { label: 'Single-Premium Whole Life (SPWL)', value: 'spwl' },
  { label: 'Guaranteed Universal Life (GUL)', value: 'gul' },
  { label: 'Whole Life', value: 'wholeLife' },
  { label: 'Indexed Universal Life (IUL) ', value: 'iul' },
];

export const DEFAULT_OFFERING = { label: 'Term Life', value: 'term' };
export const DEFAULT_PERM_OFFERING = { label: 'Select', value: 'select' };

const termDescription =
  'Term life insurance is the most affordable type of life insurance. It expires after a predetermined number of years, and doesn’t provide any cash value while you’re still alive.';
const permDescription =
  'Permanent life insurance is a type of life insurance policy that offers lifelong coverage, as long as you continue to pay premiums to keep your policy active. These policies are more expensive than other types of life insurance because they don’t expire and they offer a permanent death benefit, as well as a cash value component that may be a source of tax-deferred growth. Whole life and universal life insurance are two popular types of permanent life insurance. ';
const spwlDescription =
  'Single-premium whole life insurance is the type of whole life insurance where everything is paid up front. The policy comes with a cash-value component in addition to the lump-sum death benefit payout.';
const gulDescription =
  'Guaranteed universal life insurance (GUL) is a type of permanent life insurance, and one of the most affordable and convenient ways to get lifelong coverage. GUL policies come with fixed premiums and a fixed death benefit, so you know what you’ll pay every month and exactly how much the policy will pay out when you pass away.';
const wholeDescription =
  'Whole life insurance is a type of permanent life insurance, which means it lasts your entire life. It comes with a cash value component in addition to the lump-sum death benefit payout.';
const iulDescription =
  'Indexed Universal Life insurance (IUL) is a type of permanent life insurance that is intended to last for your entire life and can come with a cash value that earns interest based on the performance of a stock market index such as the S&P 500.';

export const PRODUCT_DESCRIPTION = {
  term: termDescription,
  perm: permDescription,
  spwl: spwlDescription,
  gul: gulDescription,
  wholeLife: wholeDescription,
  iul: iulDescription,
};

export const MOTIVATIONS = [
  {
    label: 'Protect My Family / Replace My Income',
    value: 'Protect My Family / Replace My Income',
  },
  { label: 'Leave a Legacy / Estate Conservation', value: 'Leave a Legacy / Estate Conservation' },
  { label: 'Long-Term Financial Growth', value: 'Long-Term Financial Growth' },
];

export const PAYMENT_MODES = [
  { label: 'Monthly', value: 'monthly' },
  { label: 'Annually', value: 'annually' },
  { label: 'Quarterly', value: 'quarterly' },
  { label: 'Semi-Annually', value: 'semi_annually' },
];

export const SPWL_PAYMENT_MODE = [{ label: 'Single Payment', value: 'single_payment' }];

export const GUARANTEED_AGE = [
  { label: '90', value: 90 },
  { label: '95', value: 95 },
  { label: '100', value: 100 },
  { label: '105', value: 105 },
  { label: '110', value: 110 },
  { label: '115', value: 115 },
  { label: '121', value: 121 },
];

export const PAY_AGE = [
  { label: 'Single Pay', value: 'Single Pay' },
  { label: 'Short Pay', value: 'Short Pay' },
  { label: 'Survivorship', value: 'Survivorship' },
  { label: 'Pay to 65', value: 'Pay to 65' },
  { label: 'Pay to 100', value: 'Pay to 100' },
];
