import { HelpItem } from './types';

export const gettingStartedWithPgProItems: HelpItem[] = [
  {
    displayText: 'Policygenius Pro platform demo video',
    link: 'https://resources.zinnia.com/hubfs/Policygenius%20Pro%20Walkthrough%20Video%202.23.mp4',
  },
  {
    displayText: 'Policygenius Pro advisor handbook',
    link: 'https://resources.zinnia.com/hubfs/BD%20Resources/Policygenius%20Pro%20Advisor%20Handbook.pdf',
  },
  {
    displayText: 'Client case study video',
    link: 'https://resources.zinnia.com/hubfs/BD%20Resources/Policygenius%20Pro%20Client%20Case%20Study%20%231.mp4',
  },
  {
    displayText: 'Talking term life insurance',
    link: 'https://resources.zinnia.com/hubfs/Educational%20Videos/Talking%20Term%20Life%20Insurace.mp4',
  },
  {
    displayText: 'Life insurance health classifications',
    link: 'https://www.policygenius.com/life-insurance/what-are-life-insurance-classifications/',
  },
  {
    displayText: 'Case report - Glossary of terms',
    link: 'https://resources.zinnia.com/hubfs/BD%20Resources/Case%20Report%20Overview.pdf',
  },
  {
    displayText: 'Client journey overview - Fully underwritten',
    link: 'https://resources.zinnia.com/hubfs/BD%20Resources/PgPro%20Client%20Journey%20-%20Fully%20Underwritten.pdf',
  },
  {
    displayText: 'Client journey overview - Accelerated underwriting',
    link: 'https://resources.zinnia.com/hubfs/BD%20Resources/PgPro%20Client%20Journey%20-%20Accelerated%20Underwriting.pdf',
  },
];

export const resourcesForAdvisorsItems: HelpItem[] = [
  {
    displayText: 'Email templates for client outreach',
    link: 'https://resources.zinnia.com/hubfs/BD%20Resources/Partner%20Marketing%20Emails.pdf',
  },
  {
    displayText: 'Social media ads and posters',
    link: 'https://resources.zinnia.com/hubfs/BD%20Resources/Policygenius%20Pro%20Social%20Media%20and%20Office%20Assets.pdf',
  },
  {
    displayText: 'Client handout: Fully underwritten client journey',
    link: 'https://resources.zinnia.com/hubfs/BD%20Resources/PgPro%20Customer%20Journey%20Overview.pdf',
  },
  {
    displayText: 'Client Handout: The do’s and dont’s of the life insurance medical exam',
    link: 'https://resources.zinnia.com/hubfs/Your%20life%20insurance%20medical%20exam.pdf',
  },
  {
    displayText: 'eBook: 8 Life insurance myths, debunked',
    link: 'https://resources.zinnia.com/hubfs/BD%20Resources/PgPro%20Life%20Insurance%20Myths%20Whitepaper.pdf',
  },
];

export const carrierNoticePDFs = {
  aig: {
    name: 'Corebridge Financial',
    url: 'https://storage.googleapis.com/pg-production-carrier-producer-notices/corebridge.pdf',
  },
  'aig-ny': {
    name: 'Corebridge Financial',
    url: 'https://storage.googleapis.com/pg-production-carrier-producer-notices/corebridge.pdf',
  },
  'banner-life': {
    name: 'Banner Life',
    url: 'https://storage.googleapis.com/pg-production-carrier-producer-notices/banner-wp.pdf',
  },
  'william-penn': {
    name: 'William Penn',
    url: 'https://storage.googleapis.com/pg-production-carrier-producer-notices/banner-wp.pdf',
  },
  'lincoln-national-life': {
    name: 'Lincoln National Life',
    url: 'https://storage.googleapis.com/pg-production-carrier-producer-notices/lincoln.pdf',
  },
  'lincoln-ny': {
    name: 'Lincoln National Life',
    url: 'https://storage.googleapis.com/pg-production-carrier-producer-notices/lincoln.pdf',
  },
  'mutual-of-omaha': {
    name: 'Mutual of Omaha',
    url: 'https://storage.googleapis.com/pg-production-carrier-producer-notices/moo.pdf',
  },
  paclife: {
    name: 'Pacific Life',
    url: 'https://storage.googleapis.com/pg-production-carrier-producer-notices/paclife.pdf',
  },
  symetra: {
    name: 'Symetra',
    url: 'https://storage.googleapis.com/pg-production-carrier-producer-notices/symetra.pdf',
  },
  'symetra-ny': {
    name: 'Symetra',
    url: 'https://storage.googleapis.com/pg-production-carrier-producer-notices/symetra.pdf',
  },
  transamerica: {
    name: 'Transamerica',
    url: 'https://storage.googleapis.com/pg-production-carrier-producer-notices/transamerica.pdf',
  },
  'savings-bank-life-insurance': {
    name: 'Savings Bank Life Insurance',
    url: 'https://storage.googleapis.com/pg-production-carrier-producer-notices/sbli.pdf',
  },
};
