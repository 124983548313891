import { Col, Row, theme, TitleText, UtilityText, Link as MortarLink } from '@policygenius/mortar';
import Link from 'components/Link';
import styled from 'styled-components';

export const HelpCenterContainer = styled(Row)`
  padding: 32px 16px 30px 16px;
  background-color: ${theme.colors.white};
  padding-bottom: 80px;
  @media (min-width: ${theme.breakpoints.medium}px) {
    padding: 64px 24px 120px 24px;
  }
`;

export const GettingStartedContainer = styled.div`
  margin-top: 40px;
`;

export const ItemLink = styled(Link)`
  text-decoration: none;
`;

export const HelpItem = styled.div`
  border: 1px solid ${theme.colors.gray200};
  border-radius: 4px;
  padding: 16px;
  margin-top: 8px;
  display: flex;
  p {
    width: 100%;
  }
  &:hover {
    border-color: ${theme.colors.orange400};
    background-color: ${theme.colors.orange100};
  }
  &:active {
    border-color: ${theme.colors.orange400};
    background-color: ${theme.colors.orange100};
  }
`;

export const HelpItemText = styled(UtilityText)`
  line-height: 32px;
`;

export const ResourcesForAdvisors = styled.div`
  margin-top: 24px;
`;

export const ContactUsContainer = styled(Col)`
  margin-top: 48px;
`;

export const ContactUsTitle = styled(TitleText)`
  margin-bottom: 16px;
`;

export const ContactUsSection = styled.div`
  margin-bottom: 12px;
`;

export const UtilityLink = styled(UtilityText)`
  text-decoration: underline;
`;

export const ShareFeedbackLink = styled(MortarLink)`
  text-decoration: none;
`;
