import React from 'react';
import { Button, LoadingIndicator, TitleText } from '@policygenius/mortar';
import { FORK_ROUTE } from 'routes/constants';
import { Header, Buttons, EmailCSV } from './styles';
import { useNavigate } from 'react-router-dom';
import { Props } from './types';
import { HIDE_EMAIL_CSV } from 'utils/optimizelyConstants';
import { useDecision } from '@optimizely/react-sdk';

const ReportsHeader: React.FC<Props> = ({ loading, producer, assistant, emailCsvOnClick }) => {
  const navigate = useNavigate();

  const [hideEmailCsvFlag, optimizelyClientReady] = useDecision(HIDE_EMAIL_CSV);
  const hideEmailCsvFlagActive = hideEmailCsvFlag.enabled && optimizelyClientReady;

  return (
    <Header>
      <TitleText size="large" color="black">
        Case report
      </TitleText>
      <Buttons>
        {!hideEmailCsvFlagActive && (
          <EmailCSV onClick={() => emailCsvOnClick()} variant="filled" size="medium">
            {loading ? <LoadingIndicator color="gray500" /> : 'Email CSV'}
          </EmailCSV>
        )}
        {(producer || assistant) && (
          <Button onClick={() => navigate(FORK_ROUTE)} variant="black" size="medium">
            New case
          </Button>
        )}
      </Buttons>
    </Header>
  );
};

export default ReportsHeader;
