/* THIS FILE WAS AUTO-GENERATED. DO NOT MODIFY */
/* eslint-disable */
import * as Types from '../../graph/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PaginatedReportsQueryVariables = Types.Exact<{
  pageToken: Types.Scalars['String']['input'];
  pageSize: Types.Scalars['Int']['input'];
  orderBy?: Types.InputMaybe<Types.OrderBy>;
  filter: Types.Scalars['String']['input'];
}>;


export type PaginatedReportsQuery = { __typename?: 'Query', paginatedReports: { __typename?: 'PaginatedReports', nextPageToken: string, totalSize: number, reports?: Array<{ __typename?: 'StatusReport', applicationStatus: Types.ApplicationStatus, applicationStage: Types.ApplicationStage, createdDate: string, referenceId: string, updatedDate: string, creationMethod: Types.CreationMethod, client: { __typename?: 'ReportClient', firstName: string, lastName: string }, referringAgent: { __typename?: 'ReferringAgent', firstName: string, lastName: string, uuid: string, agencyName: string }, lifeAppliedPolicy?: { __typename?: 'LifeAppliedPolicy', carrier: string, term: number, coverageAmount: number, premium: number, product: Types.ProductType } | null }> | null } };


export const PaginatedReportsDocument = gql`
    query PaginatedReports($pageToken: String!, $pageSize: Int!, $orderBy: OrderBy, $filter: String!) {
  paginatedReports(
    input: {pageToken: $pageToken, pageSize: $pageSize, orderBy: $orderBy, filter: $filter}
  ) {
    nextPageToken
    totalSize
    reports {
      applicationStatus
      applicationStage
      createdDate
      referenceId
      updatedDate
      client {
        firstName
        lastName
      }
      referringAgent {
        firstName
        lastName
        uuid
        agencyName
      }
      lifeAppliedPolicy {
        carrier
        term
        coverageAmount
        premium
        product
      }
      creationMethod
    }
  }
}
    `;

/**
 * __usePaginatedReportsQuery__
 *
 * To run a query within a React component, call `usePaginatedReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedReportsQuery({
 *   variables: {
 *      pageToken: // value for 'pageToken'
 *      pageSize: // value for 'pageSize'
 *      orderBy: // value for 'orderBy'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function usePaginatedReportsQuery(baseOptions: Apollo.QueryHookOptions<PaginatedReportsQuery, PaginatedReportsQueryVariables> & ({ variables: PaginatedReportsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PaginatedReportsQuery, PaginatedReportsQueryVariables>(PaginatedReportsDocument, options);
      }
export function usePaginatedReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaginatedReportsQuery, PaginatedReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PaginatedReportsQuery, PaginatedReportsQueryVariables>(PaginatedReportsDocument, options);
        }
export function usePaginatedReportsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PaginatedReportsQuery, PaginatedReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PaginatedReportsQuery, PaginatedReportsQueryVariables>(PaginatedReportsDocument, options);
        }
export type PaginatedReportsQueryHookResult = ReturnType<typeof usePaginatedReportsQuery>;
export type PaginatedReportsLazyQueryHookResult = ReturnType<typeof usePaginatedReportsLazyQuery>;
export type PaginatedReportsSuspenseQueryHookResult = ReturnType<typeof usePaginatedReportsSuspenseQuery>;
export type PaginatedReportsQueryResult = Apollo.QueryResult<PaginatedReportsQuery, PaginatedReportsQueryVariables>;