import { LabelText, UtilityText } from '@policygenius/mortar';
import React from 'react';
import { Card } from './styles';

interface Props {
  title: string;
  description: string;
}

export const ProductDecisionCard: React.FC<Props> = ({ title, description, children }) => {
  return (
    <Card>
      <LabelText size="large">{title}</LabelText>
      <UtilityText size="small">{description}</UtilityText>
      {children}
    </Card>
  );
};
