import React, { useState } from 'react';
import { Button, Col, LabelText, TitleText, UtilityText } from '@policygenius/mortar';
import {
  HelpCenterContainer,
  GettingStartedContainer,
  HelpItem,
  ResourcesForAdvisors,
  ContactUsContainer,
  ContactUsSection,
  ContactUsTitle,
  ItemLink,
  HelpItemText,
  UtilityLink,
  ShareFeedbackLink,
} from './styles';
import {
  gettingStartedWithPgProItems,
  resourcesForAdvisorsItems,
  carrierNoticePDFs,
} from './constants';
import { RightChevron } from './RightChevron';
import { Background } from 'components/Background';
import { useCurrentUserQuery } from 'query/currentUser/query';
import { Role } from 'graph/types';

const HelpCenter: React.FunctionComponent = () => {
  const [activeItem, setActiveItem] = useState<string>();
  const { data: userData, error } = useCurrentUserQuery();

  if (error) {
    console.error(error);
  }

  const carrierURLs =
    userData?.currentUser?.carriers
      .slice()
      .sort()
      .map((carrier) => {
        return carrierNoticePDFs[carrier as keyof typeof carrierNoticePDFs];
      })
      .filter((carrier) => carrier) || [];

  const uniqueCarrierURLs = [...new Map(carrierURLs.map((m) => [m.name, m])).values()];

  const isAssistant = userData?.currentUser.roles.includes(Role.Assistant);

  return (
    <Background>
      <HelpCenterContainer>
        <Col
          span={{
            large: 11,
            medium: 11,
            small: 4,
          }}
          push={{
            large: 1,
            medium: 1,
          }}
        >
          <TitleText
            size={{
              large: 'xlarge',
              medium: 'xlarge',
              small: 'large',
            }}
          >
            Help Center
          </TitleText>
        </Col>
        <Col
          span={{
            large: 7,
            medium: 10,
            small: 4,
          }}
          push={{
            large: 1,
            medium: 1,
          }}
        >
          <GettingStartedContainer>
            <TitleText size="small">Getting started with Policygenius Pro</TitleText>
            {gettingStartedWithPgProItems.map((item) => (
              <ItemLink
                key={item.displayText}
                href={item.link}
                target="_blank"
                rel="no opener noreferrer"
                onMouseEnter={() => setActiveItem(item.displayText)}
                onMouseLeave={() => setActiveItem('')}
                analyticsProperties={{
                  source: 'help center',
                  text: item.displayText.toLowerCase(),
                }}
                external
              >
                <HelpItem>
                  <HelpItemText size="small">{item.displayText}</HelpItemText>
                  <RightChevron active={item.displayText === activeItem} />
                </HelpItem>
              </ItemLink>
            ))}
          </GettingStartedContainer>
        </Col>
        <ContactUsContainer
          span={{
            large: 2,
            medium: 0,
            small: 0,
          }}
          push={{
            large: 9,
          }}
        >
          <ContactUsTitle size="small">Contact us</ContactUsTitle>
          <ContactUsSection>
            <LabelText size="small">For inquiries and questions</LabelText>
            <UtilityLink size="xsmall" forwardedAs="a" href="mailto:pgproaccounts@policygenius.com">
              pgproaccounts@policygenius.com
            </UtilityLink>
          </ContactUsSection>
          <ContactUsSection>
            <LabelText size="small">Tell us what you think</LabelText>
            <UtilityText size="xsmall">
              We loving hearing your feedback on our product and processes.
            </UtilityText>
          </ContactUsSection>
          <ShareFeedbackLink
            href={`https://survey.alchemer.com/s3/6828497/PgPro-Case-Reporting-Feedback?name=Lori%20Ing&email=${userData?.currentUser.email}&agency=${userData?.currentUser.agencySlug}`}
            target="_blank"
            rel="no opener noreferrer"
          >
            <Button variant="filled" size="small">
              Share feedback
            </Button>
          </ShareFeedbackLink>
        </ContactUsContainer>
        <Col
          span={{
            large: 7,
            medium: 10,
            small: 4,
          }}
          push={{
            large: 1,
            medium: 1,
          }}
        >
          <ResourcesForAdvisors>
            <TitleText size="small">Marketing resources</TitleText>
            {resourcesForAdvisorsItems.map((item) => (
              <ItemLink
                key={item.displayText}
                href={item.link}
                target="_blank"
                rel="no opener noreferrer"
                onMouseEnter={() => setActiveItem(item.displayText)}
                onMouseLeave={() => setActiveItem('')}
                analyticsProperties={{
                  source: 'help center',
                  text: item.displayText.toLowerCase(),
                }}
                external
              >
                <HelpItem>
                  <HelpItemText size="small">{item.displayText}</HelpItemText>
                  <RightChevron active={item.displayText === activeItem} />
                </HelpItem>
              </ItemLink>
            ))}
          </ResourcesForAdvisors>
        </Col>
        {userData?.currentUser.agencyType === 'BROKER_OF_RECORD' &&
          uniqueCarrierURLs.length > 0 &&
          !isAssistant && (
            <Col
              span={{
                large: 7,
                medium: 10,
                small: 4,
              }}
              push={{
                large: 1,
                medium: 1,
              }}
            >
              <ResourcesForAdvisors>
                <TitleText size="small">Carrier notices</TitleText>
                {uniqueCarrierURLs.map((carrier) => (
                  <ItemLink
                    key={carrier.name}
                    href={carrier.url}
                    target="_blank"
                    rel="no opener noreferrer"
                    onMouseEnter={() => setActiveItem(carrier.name)}
                    onMouseLeave={() => setActiveItem('')}
                    analyticsProperties={{
                      source: 'help center',
                      text: carrier.name,
                    }}
                    external
                  >
                    <HelpItem>
                      <HelpItemText size="small">{carrier.name}</HelpItemText>
                      <RightChevron active={carrier.name === activeItem} />
                    </HelpItem>
                  </ItemLink>
                ))}
              </ResourcesForAdvisors>
            </Col>
          )}
        <ContactUsContainer
          span={{
            large: 0,
            medium: 5,
            small: 4,
          }}
          push={{
            medium: 1,
          }}
        >
          <ContactUsTitle size="small">Contact us</ContactUsTitle>
          <ContactUsSection>
            <LabelText size="small">For inquiries and questions</LabelText>
            <UtilityLink size="xsmall" forwardedAs="a" href="mailto:pgproaccounts@policygenius.com">
              pgproaccounts@policygenius.com
            </UtilityLink>
          </ContactUsSection>
          <ContactUsSection>
            <LabelText size="small">Tell us what you think</LabelText>
            <UtilityText size="xsmall">
              We loving hearing your feedback on our product and processes.
            </UtilityText>
          </ContactUsSection>
          <ShareFeedbackLink
            href="https://survey.alchemer.com/s3/6828497/PgPro-Case-Reporting-Feedback?name=Lori%20Ing&email=lori.ing@policygenius.com&agency=policygenius"
            target="_blank"
            rel="no opener noreferrer"
          >
            <Button variant="filled" size="small">
              Share feedback
            </Button>
          </ShareFeedbackLink>
        </ContactUsContainer>
      </HelpCenterContainer>
    </Background>
  );
};

export default HelpCenter;
